import { SettingsCookie } from 'Shared/utils';

// This file only loaded for the in-page login
onDomReady(function () {
   const rootElement = $('login');
   history.replaceState(
      { loginPanel: rootElement.get('data-initial') },
      window.title,
      window.location.href
   );

   $(window).addEvent('popstate', function (event) {
      const state = event.event.state;
      const cssClass = state && state.loginPanel;
      if (cssClass) {
         LoginPanelManager.showPanel(cssClass);
      }
   });

   $$('.formToggle').addEvent('click', function (event) {
      event.preventDefault();
      const link = event.target;
      const cssClass = link.get('data-action');
      const title = link.innerText;
      const url = link.get('href');
      history.pushState({ loginPanel: cssClass }, title, url);
   });

   function goToLastPage() {
      // If this was opened in a new window (e.g., target="_blank" rel="noreferrer"), then
      // close it and refresh the original window. The assumption is that this
      // behavior is more desirable that redirecting to last viewed page if
      // the query parameter close_after is set.
      if (
         new URI().getData('close_after') === '1' &&
         window.opener &&
         window.opener.location.hostname === window.location.hostname
      ) {
         window.opener.location.reload();
         window.close();
      }

      let loc = SettingsCookie.get('last_page') || '/';

      window.location = decodeURIComponent(loc);
   }
   listenGlobal('login.success', goToLastPage);
});
